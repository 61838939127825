<template>
  <div class="page1">
    <div class="content">
      <!--搜索条件 -->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <el-select v-model="formInline.parkType">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="路内车场" :value="1"></el-option>
                  <el-option label="路外车场" :value="2"></el-option>
                </el-select>
                <!-- <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select> -->
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.charge_type')">
            <el-select v-model="formInline.chargeType">
                <el-option label="计费1" value="1"></el-option>
                <el-option label="计费2" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Vehicle_ype')">
            <el-select v-model="formInline.chargeType">
                <el-option label="全部" value=""></el-option>
                <el-option label="不含无牌车" value="1"></el-option>
                <el-option label="无牌车" value="2"></el-option>
            </el-select>
          </el-form-item> -->

              <el-form-item :label="$t('searchModule.park_name')">
                <!-- <el-input v-model="formInline.parkName" placeholder="请输入车场名称"></el-input> -->
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                ></a-park-select>
              </el-form-item>
              <el-form-item label="时间">
                <a-date-picker
                  ref="datePicker"
                  :selectkeys="selectkeys"
                  :isPreviousday="isPreviousday"
                  :YearShowHidden="selectkeys[3]"
                ></a-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <!-- <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="exportFile"
            :loading="loading"
            v-if="$route.meta.authority.button.export"
            >{{ $t('button.export') }}</el-button
          > -->
              <el-button type="info" icon="el-icon-delete" @click="empty()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportHandler()"
                :loading="loading"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          v-loading="loading"
          border
          :header-cell-style="{ background: '#EFF2F7' }"
          :data="tableData"
          style="text-align: center"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, dateFormat, formatDuring } from "@/common/js/public";
export default {
  name: "parkingOperationAnalysis",
  components: {
    // myComponent,
  },
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);
    return {
      modelvalue: "",
      formInline: {
        parkId: "",
        parkName: "",
        showDate: "day",
        chargeType: "",
        parkType: "",
      },
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate;
        },
      },
      temParkData: {},
      selectkeys: ["date", "month", "week", "year", "datetime"],
      isPreviousday: true,
      value1: newDate,
      value2: "",
      value3: "",
      lastDay: 0,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: "车场",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
        },
        {
          prop: "operation",
          label: this.$t("list.Belonging_operator"),
        },
        //  {
        //   prop: "qre3",
        //   label: this.$t("list.charge_type"),
        // },
        {
          prop: "datasource",
          label: this.$t("list.Type_of_parking_lot"),
        },
        {
          prop: "shouldpayNum",
          label: "应收订单数",
          formatter: (row, column) => {
            return row.shouldpayNum ? row.shouldpayNum : "0";
          },
        },
        {
          prop: "actualpayNum",
          label: "实收订单数",
          formatter: (row, column) => {
            return row.actualpayNum ? row.actualpayNum : "0";
          },
        },
        {
          prop: "arrearageNum",
          label: "欠费订单数",
          formatter: (row, column) => {
            return row.arrearageNum ? row.arrearageNum : "0";
          },
        },
        {
          prop: "actualPayNumRate",
          label: "订单实缴率",
          formatter: (row, column) => {
            if (row.actualPayNumRate) {
              return (row.actualPayNumRate * 100).toFixed(2) + "%";
            } else {
              return "0.00%";
            }
          },
        },
        {
          prop: "shouldpay",
          label: this.$t("list.amount_receivable"),
          formatter: (row, column) => {
            if (row.shouldpay) {
              return (row.shouldpay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualpay",
          label: this.$t("list.Actual_received_amount"),
          formatter: (row, column) => {
            if (row.actualpay) {
              return (row.actualpay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "arrearageAmount",
          label: this.$t("list.arrears"),
          formatter: (row, column) => {
            if (row.arrearageAmount) {
              return (row.arrearageAmount / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualPayMoneyRate",
          label: "金额实缴率",
          formatter: (row, column) => {
            if (row.actualPayMoneyRate) {
              return (row.actualPayMoneyRate * 100).toFixed(2) + "%";
            } else {
              return "0.00%";
            }
          },
        },
        {
          prop: "avgBerthIncome",
          label: "平均泊位收入",
          formatter: (row, column) => {
            if (row.avgBerthIncome) {
              return (row.avgBerthIncome / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "customerUnitPrice",
          label: "客单价",
          formatter: (row, column) => {
            if (row.customerUnitPrice) {
              return (row.customerUnitPrice / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
    };
  },
  methods: {
    parkClear() {
      // this.$refs.parkSelect.clear();
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    querySearchAsync(queryString, cb) {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
            areaIds: areaId,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    empty() {
      this.areaId = "";
      this.streetId = "";
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.formInline.parkName = "";
      this.formInline.parkType = "";
      this.$refs.parkSelect.clear();
    },

    /**
     * 导出
     */
    exportHandler() {
      let data = {
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        // parkName: this.formInline.parkName,
        parkType: this.formInline.parkType,
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
      };
      exportExcelNew("/acb/2.0/parkRevenueAnalysis/revenueList/export", data, "post");
    },

    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/parkRevenueAnalysis/revenueList", {
          data: {
            // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            // parkName: this.formInline.parkName,
            parkType: this.formInline.parkType,
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            // reportType: reportType,
            // reportDate: this.formInline.time,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeDateType() {},
    selectDay(selectDay) {
      this.lastDay = selectDay;
    },
    exportFile() {
      // let reportType = 1;
      // if (this.formInline.showDate == "day") {
      //   reportType = 1;
      //   this.formInline.time = dateFormat(this.value1, "yyyy-MM-dd");
      // } else if (this.formInline.showDate == "month") {
      //   reportType = 2;
      //   this.formInline.time = dateFormat(this.value2, "yyyy-MM");
      // } else if (this.formInline.showDate == "year") {
      //   this.formInline.time = dateFormat(this.value3, "yyyy");
      //   reportType = 3;
      // }
      // if (this.formInline.showDate == "") {
      //   this.$alert("请选择日期！", "提示");
      //   return;
      // }
      // if (this.formInline.time == "") {
      //   this.$alert("请选择日期！", "提示");
      //   return;
      // }
      exportExcelNew("/acb/2.0/parkReport/analysisExport", {
        // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        // reportType: reportType,
        // reportDate: this.formInline.time,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // reportType: reportType,
        // reportDate: this.formInline.time,
      });
    },
    handleCurrentChange(currentpage) {
      this.pageNum = currentpage;
      this.searchData();
    },
  },
  mounted() {
    // console.log("-->$route.meta.authority", this.$route.meta.authority);
    this.searchData();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
